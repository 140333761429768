import React from 'react';
import {Link} from 'gatsby';
import isEmail from 'validator/lib/isEmail';

import tw, {styled} from 'twin.macro';
import axios from "axios";
import {apiUrl} from "@/api";

const Wrapper = styled.footer(
	() => [
		tw`max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8`
	]
);

const Error = styled.p(
	() => [
		tw`block mt-2 text-red-500 text-sm`
	]
);

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {

}

const Footer: React.FunctionComponent<Props> = (props: Props) => {
	const [sent, setSent] = React.useState(false);
	const [email, setEmail] = React.useState("");
	const [error, setError] = React.useState("");

	const subscribe = () => {
		if (!isEmail(email)) {
			setError("invalid email address");
			return;
		}

		axios.post(
			apiUrl("newsletter/subscribe"), JSON.stringify({email})
		).then((response) => {
			if (response.data.success) {
				setSent(true);
				setEmail("");
			} else {
				setError("Server error");
			}
		}).catch(console.error);
	};

	return (
		<Wrapper>
			<h2 className="sr-only">Footer</h2>
			<div className="xl:grid xl:grid-cols-3 xl:gap-8">
				<div className="grid xl:col-span-2">
					<div className="md:grid md:grid-cols-3 md:gap-20">
						<div>
							<h4 className="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
								VPN servers
							</h4>
							<ul className="mt-4 space-y-4">
								<li>
									<Link to={"/create/openvpn/"}
										 className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Openvpn
									</Link>
								</li>
								<li>
									<Link to={"/create/wireguard/"}
										 className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Wireguard
									</Link>
								</li>
							</ul>
						</div>
						<div className="mt-12 md:mt-0">
							<h4 className="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">Support</h4>
							<ul className="mt-4 space-y-4">
								<li>
									<Link to={"/contact"}
										 className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Contact
									</Link>
								</li>
								<li>
									<Link to={"/posts"}
										 className="text-base leading-6 text-gray-500 hover:text-gray-900">
										News
									</Link>
								</li>
								<li>
									<a href="https://kb.nologs-vpn.com" target={"__blank"}
									   className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Documentation
									</a>
								</li>
							</ul>
						</div>

						<div className="mt-12 md:mt-0">
							<h4 className="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">Legal</h4>
							<ul className="mt-4 space-y-4">
								<li>
									<Link to={"/legal/#privacy"}
										 className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Privacy
									</Link>
								</li>
								<li>
									<Link to={"/legal/#terms"}
										 className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Terms of service
									</Link>
								</li>
								<li>
									<Link to={"/legal/#refunds"}
										 className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Refunds
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="mt-8 xl:mt-0">
					{sent && (
						<div className="text-center">
							<b className="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
								Thank you
							</b>
							<p className={"text-sm text-gray-500"}>
								Please confirm your subscription by clicking on
								the link we just sent you by email
							</p>
						</div>
					)}
					{!sent && (
						<div>
							<b className="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
								Subscribe to our newsletter
							</b>
							<div className="mt-4 sm:flex sm:max-w-md">
								<label htmlFor="emailAddress" className="sr-only">
									Email address
								</label>
								<input
									id={"newsletter-email"}
									type="email"
									name={"email"}
									required
									onChange={event => setEmail(event.target.value)}
									className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base leading-6 text-gray-900 placeholder-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline focus:placeholder-gray-400 transition duration-150 ease-in-out"
									placeholder="Enter your email"
								/>
								<div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
									<button
										onClick={event => subscribe()}
										type="submit"
										className="w-full bg-orange-100 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base leading-6 font-medium text-red-600 hover:text-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
										Subscribe
									</button>
								</div>
							</div>

							{error && <Error>{error}</Error>}
						</div>
					)}
				</div>
			</div>
			<div className="mt-8 border-t border-gray-200 pt-8 grid grid-cols-1 lg:grid-cols-2">
				<p className="mt-8 text-base leading-6 flex-1 text-gray-400 md:mt-0 md:order-1">
					&copy; {(new Date()).getFullYear()} NoLogs-VPN.
				</p>
				<p className="mt-8 text-base text-right leading-6 flex-1 text-gray-400 md:mt-0 md:order-1">
					DR SOFT S.R.L - RO18669955
				</p>
			</div>
		</Wrapper>
	);
};

export default Footer;
